import React, { useEffect, useRef } from "react";

const MapContainer = () => {
  const mapRef = useRef(null);
  const latitude = -33.11537499485706;
  const longitude = -58.313231647295126;

  useEffect(() => {
    const googleMapScript = document.createElement("script");
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
    googleMapScript.async = true;
    googleMapScript.defer = true;
    googleMapScript.onload = () => {
      // Initialize the map
      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: latitude, lng: longitude },
        zoom: 17,
      });

      // Add a marker to the map
      new window.google.maps.Marker({
        position: { lat: latitude, lng: longitude },
        map,
        title: "Click to open in Google Maps",
      });
    };
    document.head.appendChild(googleMapScript);
  }, [latitude, longitude]);

  return (
    <a
      href={`https://maps.app.goo.gl/8q4DMt5eD6pSA1nM9`}
      target="_blank"
      rel="noopener noreferrer"
      style={{ display: "block", textDecoration: "none", color: "inherit" }}
    >
      <div ref={mapRef} style={{ width: "100%", height: "500px" }} />
    </a>
  );
};

export default MapContainer;
