import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="header">
      <div className="header-content">
        <img src={logo} alt="Credilit Logo" className="logo" />
        <button className="menu-toggle" onClick={toggleMenu}>
          ☰ Menu
        </button>
        <nav className={`nav ${isOpen ? "open" : ""}`}>
          <ul className="nav-list">
            <li>
              <Link to="/" onClick={toggleMenu}>
                Inicio
              </Link>
            </li>
            <li>
              <Link to="/acerca" onClick={toggleMenu}>
                Quiénes Somos
              </Link>
            </li>
            <li>
              <Link to="/servicios" onClick={toggleMenu}>
                Servicios
              </Link>
            </li>
            <li>
              <Link to="/faq" onClick={toggleMenu}>
                FAQ
              </Link>
            </li>
            <li>
              <Link to="/contacto" onClick={toggleMenu}>
                Contacto
              </Link>
            </li>
            {/* Social Media Icons */}
            <li className="social-icons">
              <a
                href="https://www.facebook.com/profile.php?id=100087258101142"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                <FontAwesomeIcon icon={faFacebook} size="lg" color="#4267B2" />{" "}
                {/* Facebook blue */}
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://instagram.com/credilit"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                <FontAwesomeIcon icon={faInstagram} size="lg" color="#C13584" />{" "}
                {/* Instagram pinkish color */}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
