import React, { useState, useEffect } from "react";
import imgDesktop1 from "../assets/images/Cover1.jpeg";
import imgDesktop2 from "../assets/images/Cover2.jpeg";
import imgDesktop3 from "../assets/images/Cover3.jpeg";
import imgMobile1 from "../assets/images/MobileCover1.jpeg";
import imgMobile2 from "../assets/images/MobileCover2.jpeg";
import imgMobile3 from "../assets/images/MobileCover3.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListDots } from "@fortawesome/free-solid-svg-icons";

const Hero = () => {
  // State to keep track of the current image index
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Array of images for desktop and mobile
  const desktopImages = [imgDesktop1, imgDesktop2, imgDesktop3];
  const mobileImages = [imgMobile1, imgMobile2, imgMobile3];

  // Choose the correct set of images based on the device type
  const images = isMobile ? mobileImages : desktopImages;

  // Effect to handle image rotation
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // 3000ms = 3 seconds

    // Clean up the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [images.length]);

  // Effect to handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section className="hero">
      <img
        src={images[currentImageIndex]}
        alt="Creditos"
        onClick={() => (window.location.href = "/contacto")}
        className="hero-image fade" // Adding a class for the fade effect
        style={{ cursor: "pointer" }}
      />
      <p>
        Créditos del Litoral SA, es una administradora de créditos, autorizada y
        supervisada por el BCU
      </p>
      <a href="/servicios" className="cta-button">
        <FontAwesomeIcon icon={faListDots} />
        Conoce Más
      </a>
    </section>
  );
};

export default Hero;
