import React from "react";
import Services from "../components/Services";
import Hero from "../components/Hero";
import MapContainer from "../components/Map";

const Home = () => {
  return (
    <div className="home">
      <Hero />

      <div className="content">
        <section id="about" className="about">
          <h2>Desde el corazón del Uruguay hacia todo el país</h2>
          <p>
            Fundada el 6 de abril de 1981 en la pintoresca ciudad de Fray
            Bentos, en el departamento de Río Negro, Créditos del Litoral S.A.
            nació con una visión clara: ser un pilar de apoyo financiero para
            las familias uruguayas. Lo que comenzó como un sueño en el litoral
            del país, hoy se ha expandido para consolidarse como una referencia
            en el mercado de créditos, con una sólida presencia en los
            departamentos de Canelones, Montevideo y Soriano.
            <br />
            <br />
            Nuestra Casa Matriz, ubicada en 18 de Julio 1139, Fray Bentos, sigue
            siendo el corazón que nos conecta con nuestras raíces.
          </p>
          <br />
          <MapContainer />
        </section>

        <Services />
      </div>
    </div>
  );
};

export default Home;
