import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({
  title = "Credilit S.A. | Servicios de Créditos y Financiamiento - Tu Mejor Opción",
  description = "Descubre nuestros servicios de préstamos personales, microfinanzas, órdenes de compra, y mucho más. Consulta online de forma rápida y segura.",
  keywords = "préstamos personales, microfinanzas, órdenes de compra, créditos, financiamiento",
  url = "https://www.credilit.com.uy",
  image = "https://www.credilit.com.uy/assets/images/BOTON-CREDITOS.jpg",
}) => {
  return (
    <Helmet>
      {/* Standard SEO */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content="Your Company Name" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      {/* Open Graph for Social Media */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:type" content="website" />

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
};

export default SEO;
