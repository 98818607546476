import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./assets/styles/main.css";
import SEO from "./SEO";
import Home from "./pages/Home";
import About from "./pages/About";
import Terminos from "./pages/Terminos";
import Servicios from "./pages/Servicios";
import FAQ from "./pages/FAQ";
import Contact from "./pages/Contact";

const App = () => (
  <Router>
    <Header />
    <SEO />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/acerca" element={<About />} />
      <Route path="/terminos" element={<Terminos />} />
      <Route path="/servicios" element={<Servicios />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/contacto" element={<Contact />} />
    </Routes>
    <Footer />
  </Router>
);

export default App;
