import React from "react";
import Hero from "../components/Hero";

const Terminos = () => {
  return (
    <div className="home">
      <Hero />

      <section id="services" className="services content">
        <h2>Términos y condiciones</h2>
        <div className="terminos-cards">
          <div className="terminos-card">
            <main>
              <section id="terminos">
                <p>
                  El presente documento describe los Términos y Condiciones (en
                  adelante los “Términos y Condiciones”) aplicables al uso de
                  los servicios ofrecidos por CRÉDITOS DEL LITORAL S.A. a través
                  del sitio web denominado “www.credilit.com.uy” (en adelante el
                  “Sitio”). <br />
                  <br />
                  SI USTED NO ACEPTA LOS TÉRMINOS Y CONDICIONES, LOS CUALES
                  TIENEN CARÁCTER OBLIGATORIO Y VINCULANTE, LE AGRADECEMOS QUE
                  SE ABSTENGA DE UTILIZAR EL SITIO Y DE REALIZAR CUALQUIER
                  ACTIVIDAD EN EL MISMO. LA UTILIZACIÓN POR LOS USUARIOS DE
                  CUALQUIERA DE LOS SERVICIOS PRESTADOS EN EL SITIO, IMPLICA LA
                  ACEPTACIÓN IMPLÍCITA Y PLENO CONOCIMIENTO DE ESTOS TÉRMINOS Y
                  CONDICIONES. CRÉDITOS DEL LITORAL S.A. SE RESERVA EL DERECHO
                  DE MODIFICAR UNILATERALMENTE ESTOS TÉRMINOS Y CONDICIONES EN
                  CUALQUIER MOMENTO. DICHAS MODIFICACIONES SERÁN VINCULANTES
                  PARA TODOS QUIENES CONTRATEN LOS SERVICIOS EN FORMA POSTERIOR
                  A LA IMPLEMENTACIÓN DE LAS MODIFICACIONES A TRAVÉS DE LA
                  PUBLICACIÓN EN EL SITIO. PARA LOS USUARIOS QUE MANTENGAN
                  RELACIONES JURÍDICAS VIGENTES CON CRÉDITOS DEL LITORAL S.A. AL
                  MOMENTO DE LA MODIFICACIÓN, LOS CAMBIOS A LOS TÉRMINOS Y
                  CONDICIONES SERÁN VÁLIDOS Y EFICACES UNA VEZ SEAN CONSENTIDOS
                  POR LOS USUARIOS A TRAVÉS DEL MEDIO QUE CRÉDITOS DEL LITORAL
                  S.A. EMPLEE A TALES EFECTOS. <br />
                  <br />
                  Definiciones: A los efectos del presente documento, los
                  siguientes términos empleados en mayúscula tendrán el
                  significado que aquí se les atribuye: “Crédito(s)” es (son) el
                  (los) Crédito(s) que podrá -pero en ningún caso estará
                  obligado a- otorgar CRÉDITOS DEL LITORAL S.A. al Usuario -en
                  caso sea aprobado el análisis crediticio del Usuario- conforme
                  a las cláusulas del Préstamo y del Documento de Adeudo y demás
                  documentación complementaria que se celebrará oportunamente
                  entre el Usuario y CRÉDITOS DEL LITORAL S.A. Los Créditos se
                  otorgarán únicamente a personas físicas residentes en Uruguay,
                  entre un monto mínimo de $ 3.000 (tres mil pesos uruguayos) y
                  $ 20.000 (veinte mil pesos uruguayos), y en un plazo para
                  podrá ser de entre 3 meses y 24 meses. “Documento de Adeudo”
                  es el título valor (conforme, pagaré, vale, etc.) emitido por
                  el Usuario a CRÉDITOS DEL LITORAL S.A. donde se documente el
                  Crédito, en caso éste sea efectivamente otorgado por CRÉDITOS
                  DEL LITORAL S.A. al Usuario. “Usuario” es cualquier persona
                  física o jurídica que haya ingresado al Sitio aceptando los
                  Términos y Condiciones, en cuyo caso será destinataria de los
                  Servicios ofrecidos a través del Sitio y podrá utilizar el
                  Simulador y solicitar (un) Crédito(s) (sin que ello implique
                  obligación alguna por parte de CRÉDITOS DEL LITORAL S.A. de
                  otorgar un Crédito). “Datos” son todos aquellos datos,
                  documentos e informaciones que le solicitará el Sitio al
                  Usuario respecto a su persona y su situación económica y
                  financiera a los efectos de realizar el análisis crediticio
                  sobre el cual CRÉDITOS DEL LITORAL S.A. podrá otorgar el
                  Crédito al Usuario. “Día Hábil” es aquel día en que estén
                  operativas las instituciones bancarias en la República
                  Oriental del Uruguay. “Préstamo” es el contrato de mutuo
                  fructífero del cual emana el Crédito, y que es celebrado entre
                  CRÉDITOS DEL LITORAL S.A. y el Usuario. “Servicios” son
                  aquellos que se definen en la cláusula 2 de los Términos y
                  Condiciones. “Simulador” es el mecanismo que -a título
                  meramente informativo e ilustrativo- provee el Sitio para que
                  el Usuario pueda explorar el capital, plazo e intereses de los
                  Créditos, sin que esto implique una oferta por parte de
                  CRÉDITOS DEL LITORAL S.A. y siempre sujeto al análisis
                  crediticio por parte de CRÉDITOS DEL LITORAL S.A. Partes son
                  el Usuario y CRÉDITOS DEL LITORAL S.A. considerados
                  conjuntamente. <br />
                  <br />
                  De los Servicios que ofrece CRÉDITOS DEL LITORAL S.A. a los
                  Usuarios: Posteriormente al registro del Usuario en el Sitio,
                  la comprobación de su dirección de correo electrónico y/o
                  número de teléfono, y aportando el Usuario los Datos
                  especificados en el Sitio de manera actualizada, veraz y
                  completa, el Usuario podrá utilizar el Simulador para
                  posteriormente realizar una solicitud formal de un Crédito.
                  CRÉDITOS DEL LITORAL S.A. podrá (sin que esto implique
                  obligación) realizar un análisis crediticio y, eventualmente y
                  a solo criterio de CRÉDITOS DEL LITORAL S.A., aceptar la
                  solicitud de Crédito del Usuario, para lo cual el Usuario
                  tendrá un plazo de 48 horas hábiles para concurrir
                  personalmente, portando su documento de identidad, al
                  corresponsal financiero que CRÉDITOS DEL LITORAL S.A. indique,
                  a los efectos de suscribir el Préstamo y el Documento de
                  Adeudo correspondientes. Sin perjuicio de lo anterior, una vez
                  recibida la solicitud de Crédito del Usuario, CRÉDITOS DEL
                  LITORAL S.A. podrá ofrecer un Crédito con otros términos
                  distintos a los solicitados por el Usuario, lo cual deberá ser
                  oportunamente aceptado por el Usuario para la posterior
                  suscripción del Préstamo y el Documento de Adeudo de acuerdo a
                  lo establecido en la cláusula anterior. Debe tenerse en cuenta
                  que el Servicio solo se encuentra disponible para Usuarios
                  que: – Sean personas físicas residentes en el Uruguay; –
                  Tengan más de 21 años de edad al momento de utilizar los
                  Servicios; – Tengan cédula de identidad vigente al momento de
                  suscribir el Préstamo y el Documento de Adeudo; – Posean una
                  antigüedad laboral mayor a 3 meses, ya sea en relación de
                  dependencia o prestación de servicios personales. A estos
                  efectos, oportunamente se le podrá solicitar al Usuario una o
                  más constancia de ingresos recientes, a satisfacción de
                  CRÉDITOS DEL LITORAL S.A. – Presenten constancia de domicilio
                  a nombre del Usuario. – Suministren los Datos que
                  oportunamente le sean requeridos. Los datos sobre el capital e
                  intereses en el plazo colocado por el Usuario en el Simulador
                  son meramente ilustrativos, reservándose el derecho CRÉDITOS
                  DEL LITORAL S.A. a realizar una oferta de Crédito al Usuario
                  con otros términos de acuerdo a lo establecido en la cláusula
                  2.2. <br />
                  <br />
                  Responsabilidad no atribuible a CRÉDITOS DEL LITORAL S.A.:
                  CRÉDITOS DEL LITORAL S.A. no se responsabiliza por cualquier
                  daño o perjuicio causado a los Usuarios por fallas en el
                  sistema, en el servidor, en la red (internet) o en o el equipo
                  del Usuario que pudiera afectar la calidad de los Servicios.
                  CRÉDITOS DEL LITORAL S.A. tampoco será responsable por
                  cualquier virus que pudiera afectar el sistema, la red
                  (internet) o el equipo del Usuario como consecuencia del
                  acceso, uso o examen del Sitio. CRÉDITOS DEL LITORAL S.A. no
                  garantiza el acceso y uso continuado o ininterrumpido del
                  Sitio, así como tampoco garantiza la existencia,
                  disponibilidad, oferta o acceso a Créditos. Asimismo, el
                  sistema puede eventualmente no estar disponible debido a
                  dificultades técnicas, mantenimiento del Sitio o fallas de
                  Internet o cualquier otra circunstancia ajena a CRÉDITOS DEL
                  LITORAL S.A. En tales casos, se procurará restablecerlo con la
                  mayor celeridad posible, sin que por ello pueda imputársele
                  algún tipo de responsabilidad a CRÉDITOS DEL LITORAL S.A.;
                  CRÉDITOS DEL LITORAL S.A. no se responsabiliza por los daños o
                  perjuicios que pueda padecer el Usuario por haber ingresado de
                  forma incompleta, incorrecta, inexacta o no veraz los Datos
                  solicitados por el Sitio. CRÉDITOS DEL LITORAL S.A. no se
                  responsabiliza por la veracidad o certeza de los Datos
                  ingresados por los Usuarios en el Sistema. En ningún caso
                  CRÉDITOS DEL LITORAL S.A. garantiza que el producto financiero
                  o Crédito solicitado por el Usuario o que surja del uso del
                  Simulador sea aprobado ni garantiza términos o condiciones de
                  contratación de un eventual producto financiero o Crédito.
                  Asimismo, CRÉDITOS DEL LITORAL S.A. no garantiza que la cuota
                  del repago que surja del Simulador sea la que aplique al
                  Cliente, en caso que sea efectivamente aprobado un Crédito y
                  que efectivamente emane del contrato de Préstamo, ello en
                  función del análisis de riesgo que realice CRÉDITOS DEL
                  LITORAL S.A. La eventual presencia de enlaces a otros sitios
                  web no implica una sociedad, relación, aprobación, respaldo
                  del Sitio a dichos sitios ni sus contenidos. CRÉDITOS DEL
                  LITORAL S.A. se reserva el derecho a no otorgar Créditos ni
                  realizar análisis crediticios, aún en situación de eventual
                  aprobación de los Usuarios, en caso de falta de
                  disponibilidad. En consecuencia, CRÉDITOS DEL LITORAL S.A. no
                  garantiza en ningún caso la disponibilidad de fondos para el
                  otorgamiento de Créditos. El Usuario, en cualquier momento,
                  puede dejar de usar el Sitio y los Servicios que ofrece sin
                  expresión de causa alguna, ello sin perjuicio de las
                  obligaciones que hubiera asumido en el (los) Documento(s) de
                  Adeudo o Préstamo (s) suscripto(s) en caso que se hubiera
                  otorgado (un) Crédito(s). CRÉDITOS DEL LITORAL S.A. se reserva
                  el derecho de solicitar algún comprobante, información o
                  documentación adicional a efectos de corroborar los Datos
                  ingresados en el Sitio (sin que ello suponga obligación alguna
                  por parte de CRÉDITOS DEL LITORAL S.A.), así como de suspender
                  temporal o definitivamente a Usuarios y/o negarse a brindar
                  los Servicios en los casos en que los Datos no hayan podido
                  ser confirmados. Se informa a los Usuarios que el Sitio podrá
                  dar de baja en cualquier momento sin expresión de causa, tanto
                  los Servicios como los Datos que hubieran sido aportados al
                  Sitio. <br />
                  <br />
                  Comunicaciones: Los Datos de contacto denunciados como propios
                  por el Usuario al registrarse en el Sitio (incluyendo de
                  manera no taxativa el correo electrónico, la dirección y el
                  número de teléfono y/o celular) serán válidos para cualquier
                  comunicación que deba realizar CRÉDITOS DEL LITORAL S.A.
                  incluyendo, de forma no taxativa, cualquier notificación o
                  intimación judicial. Será responsabilidad del Usuario
                  notificar a CRÉDITOS DEL LITORAL S.A. por cualquier cambio en
                  estos Datos de contacto en un plazo menor a dos Días Hábiles.
                  En todo caso, CRÉDITOS DEL LITORAL S.A. se reserva el derecho
                  de retirar -sin responsabilidad alguna- la oferta de Créditos
                  que pudiera haber hecho ante el más mínimo cambio en los
                  Datos. <br />
                  <br />
                  Prohibiciones: El Usuario no podrá utilizar el Sitio para
                  cualquier actividad ilegal o delictiva o que implique un
                  perjuicio para un tercero. El Usuario reconoce y acepta que la
                  reproducción, copia, modificación, distribución,
                  comercialización, descompilación, desensamblado, utilización
                  de técnicas de ingeniería inversa o de cualquier otro medio
                  para obtener el código fuente o transformación o publicación
                  de cualquier resultado de pruebas de referencias no
                  autorizadas de cualquiera de los elementos, utilidades y
                  contenidos integrados y ofrecidos dentro del Sitio constituye
                  una infracción de los derechos de propiedad intelectual,
                  obligándose, en consecuencia, a no realizar ninguna de las
                  acciones mencionadas. El Usuario mantendrá a CRÉDITOS DEL
                  LITORAL S.A., sus representantes, directores, accionistas,
                  administradores y empleados, indemnes por cualquier reclamo o
                  demanda que terceros puedan realizar con relación al uso que
                  el Usuario pudiera hacer del Sitio. <br />
                  <br />
                  Confidencialidad: CRÉDITOS DEL LITORAL S.A. garantiza la total
                  y completa confidencialidad de la información proporcionada
                  por los Usuarios. Cumplimiento con la ley 18.331 de Protección
                  de Datos Personales y Acción de “Habeas Data”: El Usuario, por
                  el solo hecho de aceptar estos Términos y Condiciones, acepta
                  que CRÉDITOS DEL LITORAL S.A. recolecte los datos personales
                  del Usuario requeridos e ingresados al Sitio, para el
                  desarrollo del Servicio y la realización del análisis
                  crediticio del Usuario. La recolección y tratamiento de los
                  datos personales será realizada por CRÉDITOS DEL LITORAL S.A.
                  Los datos ingresados por los Usuarios, quedarán guardados y
                  archivados en la base de datos de CRÉDITOS DEL LITORAL S.A.,
                  la que será gestionada conforme la normativa aplicable. A
                  requerimiento del Usuario, los datos personales almacenados
                  podrán ser rectificados, actualizados o suprimidos, pudiendo
                  asimismo ejercer todos los demás derechos que le confiere la
                  Ley 18.331 y normas modificativas, concordantes y
                  reglamentarias. EL USUARIO PRESTA SU CONSENTIMIENTO RESPECTO
                  DE QUE CRÉDITOS DEL LITORAL S.A. PODRÁ UTILIZAR LOS DATOS
                  APORTADOS POR EL USUARIO PARA ENVIAR INFORMACIÓN Y/O
                  PROMOCIONES Y/O PUBLICIDAD ASOCIADA AL SITIO Y SUS SERVICIOS.
                  Asimismo, el Usuario autoriza expresamente la utilización de
                  su información personal para llevar a cabo estudios internos
                  sobre los intereses, comportamientos y demografía de los
                  Usuarios en general, de modo de que el Sitio cuente con
                  información que le permita mejorar el Sitio y sus Servicios,
                  respetando en todos los casos la normativa aplicable y sin
                  revelar información personal ni datos sensibles que puedan ser
                  aportados por los Usuarios. Legislación aplicable y
                  jurisdicción: Los Términos y condiciones de uso y contratación
                  que surgen del presente documento, así como la relación entre
                  los Usuarios y CRÉDITOS DEL LITORAL S.A. serán regidos e
                  interpretados por las leyes de la República Oriental del
                  Uruguay. Por cualquier cuestión, diferendo, controversia o
                  litigio vinculado con el uso del Sitio o cuestiones
                  relacionadas, las Partes se someten a la jurisdicción y
                  competencia exclusiva de los Juzgados de Montevideo o del
                  domicilio del Usuario, a elección de CRÉDITOS DEL LITORAL S.A.
                  LA ACEPTACIÓN MEDIANTE LA SOLICITACIÓN DEL PRESTAMO POR PARTE
                  DEL USUARIO CONSTITUYE FIRMA ELECTRÓNICA (DECRETO Nº
                  382/2003), SUSCRIBIENDO EL USUARIO TODOS LOS TÉRMINOS Y
                  CONDICIONES AQUÍ CONTENIDOS EN CARÁCTER VINCULANTE A TODOS LOS
                  EFECTOS LEGALES QUE CORRESPONDAN.
                </p>
              </section>
            </main>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Terminos;
