import React, { useEffect } from "react";
import Hero from "../components/Hero";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const FAQ = () => {
  useEffect(() => {
    const isMobile = window.innerWidth <= 768; // Define mobile as width <= 768px
    const scrollDistance = isMobile ? 250 : 500; // Scroll less on mobile

    window.scrollTo({
      top: scrollDistance,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Hero />
      <div className="faq">
        <h1>Preguntas Frecuentes</h1>
        <ul className="faq-item open">
          <li>
            <h2>¿Cuáles son los requisitos para solicitar un préstamo?</h2>
            <ul>
              <li>Cédula de identidad vigente</li>
              <li>Ser mayor de 18 años</li>
              <li> No tener incumplimientos en el Clearing por financieras</li>
              <li>
                Credilit evalúa todas las solicitudes, envíanos la tuya que sera
                analizada por uno de nuestros oficiales de cuentas.
              </li>
            </ul>
          </li>
        </ul>
        <ul className="faq-item open">
          <li>
            <h2>¿Cómo solicito mi préstamo?</h2>
            <ul>
              <li>
                Solicítalo en nuestra página de Contacto o a vía Whatsapp al{" "}
                <a
                  href="https://wa.me/+59893679014"
                  style={{ marginLeft: "10px " }}
                >
                  <FontAwesomeIcon icon={faWhatsapp} />
                  <span>093 679 014</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <ul className="faq-item open">
          <li>
            <h2>¿Dónde pago mi cuota?</h2>
            <ul>
              <li>
                En nuestras sucursales o en cualquier local de la red de
                cobranzas Redpagos o Abitab.
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
};

export default FAQ;
